// React
import { FunctionComponent } from "react";
// Library
import { StatCard } from "@fyrstain/fhir-front-library";
// Components
import JupiterPage from "../../components/JupiterPage/JupiterPage";
// Style
import styles from "./Statistics.module.css";
// Translation
import i18n from "i18next";

const Statistics: FunctionComponent = () => {
    return (
        <JupiterPage
            titleKey='title.statistics'
            loading={false}
            fitFooter={false}
            needsLogin={true}
        >
            <>
                <div className={styles.percentageContainer}>
                    <StatCard
                        serverLibUrl= {process.env.REACT_APP_FHIR_URL ?? ""}
                        periodStart="2010-01-01"
                        periodEnd="2024-05-15"
                        language={i18n.t}
                        onError={() => { }}
                    />
                </div>
            </>
        </JupiterPage>
    );
};

export default Statistics;
