// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Statistics_percentageContainer__Xx1h\\+ {
    margin: 4.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Statistics/Statistics.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".percentageContainer {\r\n    margin: 4.5rem 0;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"percentageContainer": `Statistics_percentageContainer__Xx1h+`
};
export default ___CSS_LOADER_EXPORT___;
