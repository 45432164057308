// React
import { FunctionComponent } from "react";
// Library
import { Title } from "@fyrstain/fhir-front-library";
// Components
import JupiterPage from "../../components/JupiterPage/JupiterPage";
// Styles
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
    return (
        <JupiterPage loading={false} fitFooter={true} needsLogin={false}>
            <>
                <div className={styles.mainHomeContainer}>
                    <img
                        className={styles.homeimageIcon}
                        alt="Home_image"
                        src="/assets/home_statistics.jpg"
                    />
                    <Title level={1} prefix={'Jupiter'} />
                </div>
            </>
        </JupiterPage>
    );
};

export default Home;
